import React, { useState } from 'react';
import Select from 'react-select';
import { mutate } from 'swr';

import Container from 'components/Container/Container';
import HeroText from 'components/HeroText/HeroText';
import Dots from 'components/Icons/Dots';
import Layout from 'components/Layout';
import ProductContainer from 'components/Product/ProductContainer';
import ProductTile from 'components/Product/ProductTile';
import Seo from 'components/Seo';

import useSearch from 'data/useSearch';

import useParam from 'hooks/useParam';

import isValidTerm from 'utilities/isValidTerm';

import * as styles from 'styles/pages/anazitisi.module.scss';
import { banner } from 'styles/pages/common.module.scss';

const options = [
  { value: 'asc', label: 'Αύξουσα τιμή' },
  { value: 'desc', label: 'Φθίνουσα τιμή' },
];

const AnazitisiPage = () => {
  const [sorting, setSorting] = useState(null);
  const term = useParam('term');
  const { products, loading } = useSearch(isValidTerm(term) ? term : null, {
    revalidateOnFocus: false,
    errorRetryCount: 10,
  });

  const handleSelect = (selectedOption) => {
    switch (selectedOption.value) {
      case 'asc':
        mutate(
          term,
          [...products.sort((a, b) => a.finalPrice - b.finalPrice)],
          false
        );
        break;
      case 'desc':
        mutate(
          term,
          [...products.sort((a, b) => b.finalPrice - a.finalPrice)],
          false
        );
        break;
      default:
        break;
    }
    setSorting(selectedOption);
  };

  if (loading) {
    return (
      <Layout fullWidth={true}>
        <Seo
          title="Αναζήτηση προϊόντων"
          description={`Αναζήτησε γυναικεία ρούχα Studio 83, Decibel fashion, Madame Shou Shou, εξαιρετικές χειροποίητες δημιουργίες, αξεσουάρ και βαπτιστικά πακέτα.`}
        />
        <HeroText title="ΑΝΑΖΗΤΗΣΗ" containerClass={banner} />
        <Container style={{ paddingBottom: '4rem' }}>
          <h1 className={styles.title}>Αναζήτηση σε εξέλιξη</h1>
          <div className={styles.loaderContainer}>
            <Dots width="200px" />
          </div>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout fullWidth={true}>
      <Seo
        title="Αναζήτηση προϊόντων"
        description={`Αναζήτησε γυναικεία ρούχα Studio 83, Decibel fashion, Madame Shou Shou, εξαιρετικές χειροποίητες δημιουργίες, αξεσουάρ και βαπτιστικά πακέτα.`}
      />
      <HeroText title="ΑΝΑΖΗΤΗΣΗ" containerClass={banner} />
      <Container style={{ paddingBottom: '4rem' }}>
        <h1 className={styles.title}>Αποτελέσματα αναζήτησης</h1>
        {products && products.length > 0 ? (
          <div className={styles.subheaderContainer}>
            <p>
              {products && products.length === 1
                ? `1 προϊόν`
                : `${products.length} προϊόντα`}
            </p>
            <Select
              className={styles.control}
              value={sorting}
              label="Ταξινόμηση"
              placeholder="Ταξινόμηση"
              onChange={handleSelect}
              options={options}
              isSearchable={false}
            />
          </div>
        ) : null}
        <ProductContainer>
          {products && products.length > 0 ? (
            products.map((product) => (
              <ProductTile key={product._id} product={product} />
            ))
          ) : (
            <p data-testid="no-products-found" style={{ margin: '2rem 0' }}>
              Δεν βρέθηκαν προϊόντα…
            </p>
          )}
        </ProductContainer>
      </Container>
    </Layout>
  );
};
export default AnazitisiPage;
